import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const HomePageLayout = styled.div`
  width: 100%;

  .section-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.backgroundGreen};
    height: 85vh;
    min-height: 730px;
    padding: 9rem 9rem 6rem;

    @media (max-width: ${breakpoints.screenLG}) {
      height: 50vh;
      padding: 9rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      height: 60vh;
      min-height: 600px;
      padding: 9rem 1.5rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: 1fr;
      height: calc(100vh - 120px);
      min-height: calc(100vh - 120px);
      padding-bottom: 4rem;
    }

    .headline {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .typewriter span {
      &.blue {
        color: ${({ theme }) => theme.backgroundBlue};
      }

      &.yellow {
        color: ${({ theme }) => theme.backgroundYellow};
      }

      &.purple {
        color: ${({ theme }) => theme.backgroundPurple};
      }

      &.red {
        color: ${({ theme }) => theme.backgroundRed};
      }
    }

    .typed-cursor {
      color: transparent;
      height: 60%;
      width: 0.25em;
      background-color: ${(props) => props.theme.textLight};
      margin-left: 0.5rem;
      opacity: 0;
      animation: ${fadeIn} 0.8s ease forwards 0.4s;
    }

    h1 {
      opacity: 0;
      animation: ${fadeIn} 0.8s ease forwards 0.4s;

      @media (max-width: ${breakpoints.screenSM}) {
        font-size: 2rem;
      }

      :first-child {
        margin-right: 1.2rem;

        @media (max-width: ${breakpoints.screenSM}) {
          margin-right: 0.6rem;
        }
      }

      :nth-child(2) {
        margin-top: -1rem;

        @media (max-width: ${breakpoints.screenSM}) {
          margin-top: -0.6rem;
        }
      }
    }
  }

  .section-two {
    padding: 10rem 9rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 6rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 4rem 1.5rem;
    }

    .title {
      margin-bottom: 1rem;
    }

    .SubtitleM {
      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 50%;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        max-width: 80%;
      }
    }
  }

  .section-three {
    background-color: ${(props) => props.theme.backgroundPurple};
    padding: 10rem 9rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 6rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 6rem 1.5rem;
    }

    .quote {
      max-width: 90%;
      margin: auto;

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 95%;
      }
    }

    p {
      padding-bottom: 2rem;
    }

    h3 {
      padding-bottom: 4rem;
    }

    br {
      display: none;

      @media (max-width: ${breakpoints.screenSM}) {
        display: block;
      }
    }
  }

  .section-four {
    padding: 10rem 9rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 8rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 6rem 1.5rem;
    }

    .square {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 90%;
      margin: auto;
      padding: 6rem;
      background-color: ${(props) => props.theme.backgroundRed};
      overflow: hidden;

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 100%;
        padding: 4rem 1.5rem;
      }
    }

    h3 {
      max-width: 55%;
      padding-bottom: 5rem;
      margin: 0 auto;

      @media (max-width: ${breakpoints.screenL}) {
        max-width: 80%;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        max-width: 100%;
      }
    }

    p {
      position: relative;
      padding-top: 6rem;

      &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 6px;
        height: 1px;
        width: 100%;
        background-color: ${(props) => props.theme.backgroundPurple};
      }
    }

    .tags-left {
      margin-bottom: 3rem;
    }

    .tags-right {
      margin-bottom: 1rem;
    }
  }
`

export default HomePageLayout
