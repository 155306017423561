import React, { useEffect, useRef } from 'react'
import Typed from 'typed.js'

import { HeadlineL } from '../styles/Typography'

import { colors, theme } from '../constants/theme'

/**
 * Typed animation for the header headline.
 * @returns {StatelessComponent}
 */
const TypedHeadline = () => {
  const textOne = useRef(null)
  const textTwo = useRef(null)

  const typedOne = useRef(null)
  const typedTwo = useRef(null)

  const optionsOne = {
    strings: [
      '<span class="yellow">kul,</span>',
      '<span class="blue">jing,</span>',
      '<span class="purple"">7,</span>',
      '<span class="red">kripto,</span>',
      '<span class="yellow">kreativa,</span>',
      '<span class="red">prihodnost,</span>',
      '<span class="purple">online,</span>',
    ],
    typeSpeed: 100,
    backSpeed: 100,
    backDelay: 1600,
    startDelay: 600,
    fadeOut: true,
    loop: true,
  }

  const optionsTwo = {
    strings: [
      '<span class="yellow">resen.</span>',
      '<span class="blue">jang.</span>',
      '<span class="purple"">49.</span>',
      '<span class="red">keš.</span>',
      '<span class="yellow">operativa.</span>',
      '<span class="red">začetek.</span>',
      '<span class="purple">offline.</span>',
    ],
    typeSpeed: 100,
    backSpeed: 100,
    backDelay: 1600,
    startDelay: 800,
    fadeOut: true,
    loop: true,
  }

  useEffect(() => {
    typedOne.current = new Typed(textOne.current, optionsOne)
    typedTwo.current = new Typed(textTwo.current, optionsTwo)

    return () => {
      typedOne.current.destroy()
      typedTwo.current.destroy()
    }
  }, [])

  return (
    <>
      <div className="headline">
        <HeadlineL color={colors.white}>Če je Mint</HeadlineL>
        <HeadlineL className="typewriter" font={theme.fontSecondary} ref={textOne} />
      </div>
      <div className="headline">
        <HeadlineL color={colors.white}>je Square</HeadlineL>
        <HeadlineL className="typewriter" font={theme.fontSecondary} ref={textTwo} />
      </div>
    </>
  )
}

export default TypedHeadline
