import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { theme, colors } from '../constants/theme'

import { Marquee } from '../styles/PageLayout'
import { HeadlineM } from '../styles/Typography'

/**
 * A list of tags to be displayed in a marquee animation.
 * @param {boolean} props.isRight
 * @param {Array} props.tags
 * @returns {StatelessComponent}
 */
const TagsMarquee = ({ isRight, tags }) => (
  <Marquee>
    <HeadlineM
      font={theme.fontSecondary}
      color={colors.white}
      className={isRight ? 'marquee-right' : 'marquee-left'}
    >
      {tags.map((tag, index) => (
        <a key={index} className="tag">
          {tag}
        </a>
      ))}
    </HeadlineM>
    <HeadlineM
      font={theme.fontSecondary}
      color={colors.white}
      className={isRight ? 'marquee-right' : 'marquee-left'}
    >
      {tags.map((tag, index) => (
        <a key={index} className="tag">
          {tag}
        </a>
      ))}
    </HeadlineM>
    <HeadlineM
      font={theme.fontSecondary}
      color={colors.white}
      className={isRight ? 'marquee-right' : 'marquee-left'}
    >
      {tags.map((tag, index) => (
        <a key={index} className="tag">
          {tag}
        </a>
      ))}
    </HeadlineM>
  </Marquee>
)

TagsMarquee.propTypes = {
  isRight: PropTypes.bool,
  tags: PropTypes.array,
}

TagsMarquee.defaultProps = {
  isRight: undefined,
}

export default TagsMarquee
