import React from 'react'
import { Link } from 'gatsby'

import imgHero from '../assets/images/home-hero.png'

import SEO from '../components/SEO'
import Header from '../components/Header'
import TagsMarquee from '../components/TagsMarquee'
import TypedHeadline from '../components/TypedHeadline'

import { colors, theme } from '../constants/theme'
import { featuredProjects, tags } from '../constants/content'

import HomePageLayout from '../styles/HomePageLayout'
import { FeaturedProjects } from '../styles/PageLayout'
import { TitleL, TitleM, SubtitleM, ParagraphM } from '../styles/Typography'
import { ButtonPrimary } from '../styles/ButtonLayout'

const HomePage = () => (
  <>
    <SEO title="Mint and Square ⬝ Kreativna agencija" image={imgHero} />
    <Header linkText={colors.white} />
    <HomePageLayout>
      <div className="section-one">
        <TypedHeadline />
      </div>

      <div className="section-two">
        <TitleL className="title">Naši projekti</TitleL>
        <ParagraphM weight="300" className="SubtitleM">
          Naročniki, ki nas spodbujajo, da smo vedno boljši.
        </ParagraphM>
        <FeaturedProjects>
          {featuredProjects.map((project, index) => (
            <Link to={project.link} key={index} className="project">
              <div className="project-image">
                <img src={project.img} alt={project.title} />
              </div>
              <TitleM>{project.title}</TitleM>

              <ParagraphM font={theme.fontSecondary} className="marquee">
                {project.tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                  </span>
                ))}
              </ParagraphM>
            </Link>
          ))}
        </FeaturedProjects>
      </div>

      <div className="section-three">
        <ParagraphM weight="300" color={colors.white} className="SubtitleM">
          Agencija za novo ero
        </ParagraphM>
        <div className="quote">
          <SubtitleM color={colors.white}>
            Smo ekipa, ki verjame tudi v najbolj nore ideje. Tiste, ki vam zaradi navdušenja ne
            pustijo spati ponoči. To so ideje, za katere verjamete, da bodo spremenile svet. <br />{' '}
            <br /> Mi ne vidimo samo tistega kar je, ampak tudi tisto, kar bi lahko bilo. Mi se ne
            sprašujemo samo zakaj, ampak tudi zakaj pa ne.
          </SubtitleM>
          <Link to="/about">
            <ButtonPrimary>
              <span>Več o nas</span>
            </ButtonPrimary>
          </Link>
        </div>
      </div>

      <div className="section-four">
        <div className="square">
          <SubtitleM color={colors.white}>
            Če vam spodnje besede povzročajo skrbi, nam čimprej pišite.
          </SubtitleM>
          <div className="tags-left">
            <TagsMarquee tags={tags} />
          </div>
          <div className="tags-right">
            <TagsMarquee isRight tags={tags} />
          </div>
          <ParagraphM color={colors.purple}>
            <Link to="/projects">Poglej vse naše projekte </Link>
          </ParagraphM>
        </div>
      </div>
    </HomePageLayout>
  </>
)

export default HomePage
